
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from './local.storage.service';

/**
 * Key used to access LocalStorage BackNavigation object
 */
const KEY = "reverse-router";

/** ReverseRouterService config */
interface BackNavigationConfig {
  /** The url to navigate back */
  urlSegment: string[],

  /** The partial value of an component, e.g: FormGroup values not submited */
  partialData: any;
}

export interface RouteConfig {
  /** Key name of the component you are goig/exiting to  */
  component: string,

  /** The url to navigate back */
  address: string[],

  /** The current form value to be retrieved after navigate / navigate back */
  partialData: any
}



@Injectable({
  providedIn: 'root'
})
export class ReverseRouterService {

  private _backNavigation: Map<string, BackNavigationConfig>;

  constructor(
    private router: Router,
    private localStorageService: LocalStorageService
  ) {
    this._backNavigation = new Map()
    this.getStorage()
  }

  private getStorage() {
    const config = this.localStorageService.get(KEY)
    //const config = localStorage.getItem(KEY);
    if (config) {
      const arr = JSON.parse(config)
      if (arr.entries) {
        this._backNavigation = new Map(arr);
      }
    }
  }

  private get backNavigation(): Map<string, BackNavigationConfig> {
    return this._backNavigation;
  }

  private set backNavigation(value: Map<string, BackNavigationConfig>) {
    if (value) {
      //localStorage.setItem(KEY, JSON.stringify(Array.from(value)));
      this.localStorageService.set(KEY, JSON.stringify(Array.from(value)))
    } else {
      //localStorage.removeItem(KEY);
      this.localStorageService.remove(KEY)
    }
    this._backNavigation = value;
  }

  /**
   * Get the partial data of latest route navigated to and delete it 
   * 
   * @param component The key representing ReverseRouterService with partial data
   * @param remove Remove key from ReverseRouterService config after get it
  */
  public getPartialDataOf(component: string, remove?: boolean) {
    if (this.backNavigation) {
      let route = this.backNavigation.get(component);
      if (remove) {
        this.remove(component)
      }
      if (route) {
        return route.partialData;
      }
    }
    if (remove) {
      this.remove(component)
    }
    return undefined
  }

  /** Navigate back using ReverseRouterService config
   * 
   * @param from Key for the current url in view
   * @param fallbackUrl A default url to back to, in case of ReverseRouterService
   *  haven't the config to navigate back
  */
  public goBack(from: string, fallbackUrl: string[]) {
    if (this.backNavigation) {
      const route = this.backNavigation.get(from);
      if(route && route.urlSegment && route.urlSegment.length){
        this.router.navigateByUrl(route.urlSegment[0]);
      } else {
        this.router.navigate(fallbackUrl);
      }
    } else {
      this.router.navigate(fallbackUrl);
    }
  }

  /**
   * Navigate forward preparing to navigate back
   * 
   * @param from The config of the component you are exiting
   * @param to The config of the component you are navigating to
   */
  public navigate(from: RouteConfig, to: RouteConfig) {
    let returnTo = [];
    const backNavigation = this.backNavigation;
    const middle = this.backNavigation.get(from.component)
    if(middle){
      returnTo = middle.urlSegment;
    }
    this.backNavigation.set(from.component, {
      urlSegment: returnTo,
      partialData: from.partialData
    })
    this.backNavigation.set(to.component, {
      urlSegment: from.address,
      partialData: to.partialData
    });

    this.backNavigation = backNavigation;
    this.router.navigate(to.address)
  }

  /**
   * Set a partial data to an component
   * 
   * You don't need call `ReverseRouterService.setPartial()` before call 
   * `ReverseRouterService.navigate()`.
   * 
   * @param component The component name
   * @param partialData The partial data object
   */
  public setPartial(component: string, partialData: any) {
    const previousData: any = this.backNavigation.get(component) || {};
    previousData['partialData'] = partialData;
    this.backNavigation.set(component, previousData);
    this.backNavigation = this.backNavigation;
  }

  /** Clear the ReverseRouterService config of an component*/
  public remove(component: string) {
    if (this.backNavigation) {
      this.backNavigation.delete(component);
      this.backNavigation = this.backNavigation
    }
  }

  public clear(){
    this.backNavigation = new Map();
  }

}

// import { Injectable } from '@angular/core';

// @Injectable({
//   providedIn: 'root'
// })
// export class ReverseRouterService {

//   constructor() { }
// }
